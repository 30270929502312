import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../../Navbar'
import Bounce from 'react-reveal/Bounce';
import './AssistenciaTecnica.css'
import Footer from '../../Footer/Footer'

import { AiFillCamera} from "react-icons/ai";
import { GiVibratingSmartphone, GiPowerButton, GiComputerFan } from "react-icons/gi";
import { SlScreenSmartphone } from "react-icons/sl";
import { TbFaceId } from "react-icons/tb";
import { MdOutlineHearing, MdTouchApp } from "react-icons/md";
import { BiBattery } from "react-icons/bi";
import { BsMotherboard, BsDroplet, BsDisplay, BsFillMouse3Fill} from "react-icons/bs";
import { AiOutlineWifi } from "react-icons/ai";
import { GrPersonalComputer, GrKeyboard } from "react-icons/gr";
import {SiMicrosoftoffice, SiPcgamingwiki} from "react-icons/si";
import {CgSmartphoneChip} from "react-icons/cg";

function AssistenciaTecnica() {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div>
            <Navbar alwaysFilled={true} />
            <div>
                <div className="services-section">
                
                    <Bounce bottom>
                        
                        <div className="container-services">
                            <h1>Reparações de Smartphones</h1>
                            <div className="row-services">
                                <div className="service">
                                    <div className="icon">
                                        <AiFillCamera size={40} />
                                    </div>
                                    <h2>Câmaras</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <MdOutlineHearing size={40} />                                    
                                    </div>
                                    <h2>Auscultador</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <BiBattery size={40} />
                                    </div>
                                    <h2>Bateria</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <GiVibratingSmartphone size={40} />
                                    </div>
                                    <h2>Vibração</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <MdTouchApp size={40} />
                                        <SlScreenSmartphone size={40} />
                                    </div>
                                    <h2>Ecrã / Touch / Vidro</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <GiPowerButton size={40} />
                                    </div>
                                    <h2>Botões</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <TbFaceId size={40} />
                                    </div>
                                    <h2>Face Id</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <BsDroplet size={40} />
                                    </div>
                                    <h2>Danos Líquidos</h2>
                                </div>
                            </div>
                        </div>
                    </Bounce>
                </div>

                <hr />

               <div className="services-section">
                
                    <Bounce bottom>
                        
                        <div className="container-services">
                            <h1>Reparações de Computadores</h1>
                            <div className="row-services">
                                <div className="service">
                                    <div className="icon">
                                        <SiMicrosoftoffice size={40} />
                                    </div>
                                    <h2>Instalação de Softwares</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <GiComputerFan size={40} />                                    
                                    </div>
                                    <h2>Componentes</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <BiBattery size={40} />
                                    </div>
                                    <h2>Bateria</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <BsDisplay size={40} />
                                    </div>
                                    <h2>Substituição de Ecrã</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <GrKeyboard size={40} />
                                    </div>
                                    <h2>Teclados</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <BsFillMouse3Fill size={40} />
                                    </div>
                                    <h2>Periféricos</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <SiPcgamingwiki size={40} />
                                    </div>
                                    <h2>Computador à medida</h2>
                                </div>    
                                
                                <div className="service">
                                    <div className="icon">
                                        <CgSmartphoneChip size={40} />
                                    </div>
                                    <h2>Boards e Reballing</h2>
                                </div> 
                            </div>
                        </div>
                    </Bounce>
                </div>

           </div>
        </div>
        

 )
}

export default AssistenciaTecnica