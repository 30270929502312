import React, { useEffect } from 'react'
import '../../../../App'
import { Link } from 'react-router-dom'
import Navbar from '../../../Navbar'
import Bounce from 'react-reveal/Bounce';
import './Vendas.css'
import Footer from '../../Footer/Footer'

import { MdComputer, MdOutlinePhoneIphone, MdMicrowave } from "react-icons/md";
import { BsHeadphones } from "react-icons/bs";
import { SiVodafone, SiMicrosoftoffice } from "react-icons/si";
import { ImDisplay } from "react-icons/im";
import { FaPlaystation } from "react-icons/fa";
import { CgAppleWatch } from "react-icons/cg";

function Vendas() {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div>
            <Navbar alwaysFilled={true} />
            <div style={{ height: '100vh' }} className="services-section">
                <Bounce bottom>
                    <div className="container-services">
                        <h1>Na Neft vai encontrar tudo o que precisa! </h1>
                        
                        <div className="row-services">

                        <div className="row-services">
                            <div className="service">
                                <div className="icon">
                                    <MdComputer size={40} />
                                </div>
                                <h2>Computadores</h2>
                            </div>                            
                            </div>

                            <div className="row-services">
                                <div className="service">
                                    <div className="icon">
                                        <MdOutlinePhoneIphone size={40} />
                                    </div>
                                    <h2>Smartphones</h2>
                                </div>
                            </div>

                            <div className="row-services">
                                <div className="service">
                                    <div className="icon">
                                        <FaPlaystation size={40} />
                                    </div>
                                    <h2>Consolas</h2>
                                </div>
                            </div>

                            <div className="row-services">
                                <div className="service">
                                    <div className="icon">
                                        <MdMicrowave size={40} />
                                    </div>
                                    <h2>Eletrodomésticos</h2>
                                </div>
                            </div>

                            <div className="row-services">
                                <div className="service">
                                    <div className="icon">
                                        <CgAppleWatch size={40} />
                                    </div>
                                    <h2>Gadgets</h2>
                                </div>
                            </div>

                            <div className="row-services">
                                <div className="service">
                                    <div className="icon">
                                        <BsHeadphones size={40} />
                                        <ImDisplay size={40} />
                                    </div>
                                    <h2>Imagem / Som</h2>
                                </div>
                            </div>

                            <div className="row-services">
                                <div className="service">
                                    <div className="icon">
                                        <SiVodafone size={40} />
                                    </div>
                                    <h2>Redes</h2>
                                </div>
                            </div>

                            <div className="row-services">
                                <div className="service">
                                    <div className="icon">
                                        <SiMicrosoftoffice size={40} />
                                    </div>
                                    <h2>Licenças</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </Bounce>
            </div>
        </div>
    )
}

export default Vendas