import React, { useEffect } from 'react'
import '../../../App.css'
import Navbar from '../../Navbar'
import { Link } from 'react-router-dom'
import './Consultoria.css'
import Bounce from 'react-reveal/Bounce';
import Footer from '../Footer/Footer'


import { SlSocialInstagram } from "react-icons/sl";
import { GiMagnifyingGlass } from "react-icons/gi";
import { MdOutlineDesignServices } from "react-icons/md";
import { AiOutlineHtml5 } from "react-icons/ai";
import { RxEnvelopeClosed } from "react-icons/rx"; 


function Consultoria() {

    return (
        <div>
            <Navbar alwaysFilled={true} />
            <div>
                <div style={{ height: '100vh' }} className="services-section">
                    <Bounce bottom>
                        <div className="container-services">
                            <h1>Consultoria</h1>
                            <div className="row-services">
                                <div className="service">
                                    <div className="icon">
                                        <SlSocialInstagram size={40} />
                                    </div>
                                    <h2>Redes Sociais</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <GiMagnifyingGlass size={40} />
                                    </div>
                                    <h2>SEO</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <MdOutlineDesignServices size={40} />
                                    </div>
                                    <h2>Web Design</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <AiOutlineHtml5 size={40} />
                                    </div>
                                    <h2>WebSites</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <RxEnvelopeClosed size={40} />
                                    </div>
                                    <h2>Marketing Digital</h2>
                                </div>                              
                            </div>
                        </div>
                    </Bounce>
                </div>

            </div>
        </div>
    )
}

export default Consultoria