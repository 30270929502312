import React, { useEffect, useState } from 'react'
import '../../../App.css'
import Navbar from '../../Navbar'
import { Link, useNavigate  } from 'react-router-dom'
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';
import Footer from '../Footer/Footer'
import './Contacts.css'

import { contatos } from '../HomePage/Data'
import {
    FaFacebook,
    FaInstagram,
    FaLinkedin
  } from 'react-icons/fa';
import { ReactComponent as Logo} from '../../../assets/logo.svg'


function Contacts() {
    return (
        <div className='background-container' style={{minHeight: '100vh'}}>
            <Navbar alwaysFilled={true}/>
            <div style={{justifyContent: 'start'}}>
            <Bounce bottom>
              <div className='contacts-container'>
                <div className='flex-row-gap'>
                    <div>
                        <div className='contacts-items contacts-location'>
                            <h2>Localização</h2>
                        </div>
                        <div className='map-container'>
                            <iframe
                                className='map'
                                style={{borderRadius: '25px', marginTop: '-10px'}}
                                frameborder="0" 
                                scrolling="no" 
                                marginheight="0" 
                                marginwidth="0" 
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11928.725653974303!2d-8.348149!3d41.63021!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5dea639b13ec6040!2sNeft%20Inform%C3%A1tica!5e0!3m2!1spt-PT!2spt!4v1644456331818!5m2!1spt-PT!2spt" allowfullscreen="" loading="lazy"
                                >
                            </iframe>
                        </div>   
                    </div>
                    <div>
                        <div className='contacts-items'>
                            <h2>Email</h2>
                            <a className='no-decoration' href='mailto: neft@geral.pt'>{contatos.email}</a>
                        </div>
                        <div className='contacts-items'>
                            <h2>Telefone</h2>
                            <a className='no-decoration' href='tel:253000000'>{contatos.phone}</a>
                        </div>
                        <div className='contacts-items'>
                            <h2>Redes Sociais</h2>
                            <div className='social-icons-contacts'>
                                <a
                                className='social-icon-link-contacts'
                                href='https://www.facebook.com/neft.informatica'
                                target='_blank'
                                aria-label='Facebook'
                                >
                                <FaFacebook />
                                </a>
                                <a
                                className='social-icon-link-contacts'
                                href='https://www.instagram.com/neft_informatica/'
                                target='_blank'
                                aria-label='Instagram'
                                >
                                <FaInstagram />
                                </a>
                                <a
                                className='social-icon-link-contacts'
                                href='https://linkedin.com/company/neft-informática'
                                target='_blank'
                                aria-label='Linkedin'
                                >
                                <FaLinkedin />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
                <section className='social-media' style={{position: 'absolute'}}>
                    <div className='social-media-wrap' style={{justifyContent: 'center'}}>
                    {/* <div className='footer-logo'>
                        <Link to='/' className='social-logo'>
                        NEFT
                        </Link>
                    </div> */}
                    <small className='website-rights'>NEFT© 2023</small>
                    {/* <div className='social-icons'>
                        <a
                        className='social-icon-link'
                        href='https://www.facebook.com/neft.informatica'
                        target='_blank'
                        aria-label='Facebook'
                        >
                        <FaFacebook />
                        </a>
                        <a
                        className='social-icon-link'
                        href='https://www.instagram.com/neft_informatica/'
                        target='_blank'
                        aria-label='Instagram'
                        >
                        <FaInstagram />
                        </a>
                        <Link
                        className='social-icon-link'
                        to='/'
                        target='_blank'
                        aria-label='LinkedIn'
                        >
                        <FaLinkedin />
                        </Link>
                    </div> */}
                    </div>
                </section>
                </Bounce>
            </div>
        </div>
    )

}

export default Contacts