import React, { useEffect } from 'react'
import Hero from '../../Hero'
import Landing from '../../Landing'
import HeroList from '../../HeroList'
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour} from './Data'
import '../../../App.css'
import Pricing from '../../Pricing'
import Footer from '../Footer/Footer'
import Navbar from '../../Navbar'
import './Home.css';

import Bounce from 'react-reveal/Bounce';

import { SlSocialInstagram } from "react-icons/sl";
import { GiMagnifyingGlass } from "react-icons/gi";
import { MdOutlineDesignServices } from "react-icons/md";
import { AiOutlineHtml5 } from "react-icons/ai";
import { RxEnvelopeClosed } from "react-icons/rx"; 
import { FaServer, FaDesktop, FaCogs, FaFileContract, FaPrint, FaMailBulk, FaHtml5, FaGlobe } from 'react-icons/fa'

function Home() {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="background-container">
            <Navbar />
            <Landing {...homeObjOne}/>
            <meta http-equiv="Content-Type" content="text/html;charset=utf-8" />
            <div>
                <div className="services-section">
                    <Bounce bottom>
                        <div className="container-services">
                            <h1>Produtos em destaque</h1>
                            <meta http-equiv="Content-Type" content="text/html; charset=iso-8859-1"/>
                            <div className="row-services">
                                <div className="service">
                                    <div className="icon">
                                        <img src="./images/iphone1.png"/>
                                    </div>
                                    <h2>Smartphones e Tablets</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <img src="./images/pc1.png"/>
                                    </div>
                                    <h2>Computadores e Portáteis</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <img src="./images/impressora1.png"/>
                                    </div>
                                    <h2>Impressoras</h2>
                                </div>
                                <div className="service">
                                    <div className="icon">
                                        <img src="./images/relogio1.png"/>
                                    </div>
                                    <h2>Gadgets</h2>
                                </div> 
                                <div className="service">
                                    <div className="icon">
                                        <img src="./images/pss5.png"/>
                                    </div>
                                    <h2>Consolas</h2>
                                </div>  
                                <div className="service">
                                    <div className="icon">
                                        <img src="./images/hub.png"/>
                                    </div>
                                    <h2>Redes e Switch</h2>
                                </div> 
                                <div className="service">
                                    <div className="icon">
                                        <img src="./images/ratoteclado.png"/>
                                    </div>
                                    <h2>Periféricos</h2>
                                </div> 
                                <div className="service">
                                    <div className="icon">
                                        <img src="./images/office1.png"/>
                                    </div>
                                    <h2>Licenças</h2>
                                </div> 
                            </div>
                        </div>
                    </Bounce>
                </div>

            </div>
            <div>
            <hr />
            <div className="services-section">
                <Bounce bottom>
                <div className="container-services">
                    <h1>Serviços Disponibilizados</h1>
                    <div className="row-services">
                        <div className="service">
                            <div className="icon">
                                <FaDesktop size={40}/>
                            </div>
                            <h2>Software</h2>
                        </div>
                        <div className="service">
                            <div className="icon">
                                <FaCogs size={40}/>
                            </div>
                            <h2>Hardware</h2>
                            </div>
                        <div className="service">
                            <div className="icon">
                                <FaFileContract size={40}/>
                            </div>
                            <h2>Consultoria</h2>
                        </div>
                        <div className="service">
                            <div className="icon">
                                <FaPrint size={40}/>
                            </div>
                            <h2>Impressoras</h2>
                        </div>
                        <div className="service">
                            <div className="icon">
                                <FaMailBulk size={40}/>
                            </div>
                            <h2>Emails</h2>
                        </div>
                        <div className="service">
                            <div className="icon">
                                <FaHtml5 size={40}/>
                            </div>
                            <h2>Sites e Domínios</h2>
                        </div>
                        <div className="service">
                            <div className="icon">
                                <FaGlobe size={40}/>
                            </div>
                            <h2>Internet</h2>
                        </div>
                        <div className="service">
                            <div className="icon">
                                <FaServer size={40}/>
                            </div>
                            <h2>Redes e Servidores</h2>
                        </div>
                    </div>
                    </div>
                </Bounce>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home
