import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/pages/HomePage/Home';
import Contacts from './components/pages/Contacts/Contacts';
import AssistenciaTecnica from './components/pages/Informatica/AssistenciaTecnica/AssistenciaTecnica'
import Vendas from './components/pages/Informatica/Vendas/Vendas';
import Empresas from './components/pages/Informatica/Empresas/Empresas';
import Consultoria from './components/pages/Consultoria/Consultoria'

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={ <Home />} />
        <Route path='/contacts' element={<Contacts />} />
        <Route path='/assistenciatecnica' element={<AssistenciaTecnica />} />
        <Route path='/vendas' element={<Vendas />} />
        <Route path='/consultoria' element={<Consultoria />} />
        <Route path='/empresas' element={<Empresas />} />
        <Route path='/contacts' element={<Contacts />} />
      </Routes>
    </Router>
  );
}

export default App;