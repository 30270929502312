export const homeObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Neft',
    headline: 'Venda e Reparação de Equipamentos Informáticos',
    buttonLabel: 'Saber mais',
    imgStart: '',
    img: 'images/teamwork-building.png',
    alt: 'Neft',
    Video: 'videos/video2.mp4'
}

export const homeObjTwo = {
    id: 'Reparacoes',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Reparações',
    headline: 'Que Reparações Fazemos?',
    description: 'A nossa técnica está preparada e equipada para reparar qualquer equipamento, deste, computadores, telemóveis, televisões, monitores, etc. Orçamentos e análises gratuitas, visite-nos e repare os seus equipamentos com os melhores técnicos!',
    buttonLabel: 'Contactar',
    imgStart: 'start',
    Img: 'images/fixx.jpg',
    alt: 'Neft'


}

export const homeObjThree = {
    id: 'Vantagens',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Neft',
    headline: 'O que vendemos?',
    description: 'Cada vez mais é fundamental ter apoio especializado na área das tecnologias. Garanta um sistema de segurança e prevenção ao seu parque informático.',
    buttonLabel: 'Contactar',
    imgStart: 'end',
    img: 'list',
    alt: 'Neft',
    list: [
        { info: 'Assistência no local sem limites'},
        { info: 'Assistência remota a qualquer hora'}, 
        { info: 'O seu negócio sem paragens laborais'}, 
        { info: 'Equipamentos ideais para a sua empresa'}
    ]
}

export const homeObjFour = {
    id: 'Soluções',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Neft',
    headline: 'Soluções',
    imgStart: 'start',
    img: 'advantages',
    alt: 'Neft',
    list: [
        { info: 'Software'},
        { info: 'Hardware'}, 
        { info: 'Consultoria'}, 
        { info: 'Impressoras'},
        { info: 'Emails, Sites e Domínios'},
        { info: 'Internet, Redes e Servidores'},
        { info: 'Reparações'}
    ]
}

export const oQueENeft = {
    question: 'O que é a Neft?',
    answer: `A Neft é uma empresa dedicada ao fornecimento de soluções tecnológicas, desde a manutenção e assistência técnica a equipamentos informáticos, bem como, a sua comercialização e instalação. Além disto, dispõe de serviços de software, consultoria e apoio especializado na área das tecnologias.`
}

export const comoSurgiuANeft = {
    question: 'Como surgiu a Neft?',
    answer: `Fundada com objetivos claros, a Neft procura simplificar a procura pela tecnologia, com a satisfação máxima dos seus clientes em mente.`
}

export const contatos = {
    email: "geral@neft.pt",
    phone: "(+351) 253 049 443",
    street: "Rua Dr. Adolfo Vilela nº19 R/C",
    codPostal: "4720-019 Amares"
}

export const contratoAssistencia = {
    question: 'NEFT',
    answer: `A Neft é uma empresa dedicada ao fornecimento de soluções tecnológicas, desde a manutenção e assistência técnica a equipamentos informáticos, bem como, a sua comercialização e instalação.`
}
